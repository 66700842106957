import React, { FC } from 'react';
import './faq.scss';

interface FaqProps {}
const Faq: FC<FaqProps> = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
  <div className='faq'>
    <div className='title'>Booking Information</div>
    <div className='center italic'>
    To submit a request, please read all of the following information, then fill out my <a className="underline" href="https://thekathylee.com/booking">booking form</a> and allow 3-7 days for a response.
    </div>
    <div className="spacer"></div>

    <div className="subtitle">Deposit</div>
    A <span className='bold'>non-refundable</span> deposit ($150-300, depending on the size of the tattoo) is required to secure your appointment.
    <div className="spacer"></div>

    <div className="subtitle">Pricing </div>
    My minimum is <span className="bold">$200</span> per tattoo. If you're getting multiple letterings in one session, this minimum does not apply.<br/>
    <div className="spacer"></div>
    If you have a budget, please include that information in the initial request so I can assess feasability.
    <div className="spacer"></div>
    I will <span className='bold'>NOT</span> respond to negotiation ("this other artist will do this tattoo for less")... you alone decide who you want to be your artist, but in the end it must be a mutual decision!
    <div className="spacer"></div>

    <div className="subtitle">Booking Process </div>
    Here is an overview of the process:
    <div className="spacer"></div>
    <ul>
      <li> When you send a request, you'll receive an automated response. </li>
      <li> I'll respond (within a few business days) with any questions and to ask about dates for our potential appointment. </li>
    </ul>
    If custom:
    <ul>
      <li> I will start working on the design <span className='bold'>AFTER</span> the appointment is confirmed and the deposit is paid. </li>
      <li> If you'd like a ballpark estimate of time and cost before paying your deposit, please ask and provide:<br/><br/>
      &emsp; - a photo of the placement with the tattoo area circled  <br/>
      &emsp; - as many details as possible (style, elements, etc) <br/>
      &emsp; - relative size in inches  <br/><br/>
      <span className='bold'>Time and price estimates may change if there are major changes in the size/request.</span>
      </li>
      <li>I work on designs in chronological order for all my appointments. I'll aim to get the design to you a few days before our appointment. At this point I will provide a price and loose time estimates. Major changes may result in a redrawing fee (amount of deposit). </li>
    </ul>
    <div className="spacer"></div>

    <div className="subtitle">Scheduling </div>
    Weekend availability is very limited, and is usually booked 3+ weeks out. If you'd like an appointment sooner, please include a weekday availability.
    <div className="spacer"></div>
    Please do not schedule anything for right after our appointment as the length of tattoo sessions can vary, and the tattoo could take longer than the estimate.
    <div className="spacer"></div>

    <div className="subtitle">Rescheduling</div>
    Things happen - <span className='bold'>one</span> reschedule is allowed if you notify me at least 48 hours before your appointment.
    <div className="spacer"></div>
    In cases of insufficient notice or multiple reschedules, you will need to put down a new deposit to secure a new appointment.
    <div className="spacer"></div>

    <div className="subtitle">Payment</div>
    I accept cash or Zelle. Thank you!
    <div className="spacer"></div>
    Tattoo prep and aftercare instructions can be found <a className="underline" href="https://thekathylee.com/aftercare">here.</a>
    <div className="spacer"></div>
    You can contact me with any questions or concerns at: <a href="mailto:booking.kathylee@gmail.com">booking.kathylee@gmail.com</a>
    <div className="spacer-lg"></div>

  </div>
  );
}

export default Faq;
