import React from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import emailjs from '@emailjs/browser';
import { getFirestore, collection, addDoc } from 'firebase/firestore/lite';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './booking.scss';

const MAX_COUNT = 3;
const url='https://api.cloudinary.com/v1_1/dyqdnpave/image/upload';

enum TattooType {
  Unspecified,
  Flash = "flash",
  Custom = "custom", 
}
enum Location {
  Unspecified,
  Milpitas = "Milpitas",
  Duluth = "Duluth", 
}
enum Style {
  Unspecified,
  Black = "Black & White",
  Color = "Color", 
}
interface AvailabilityType {
  "weekdays": string[],
  "times": string[]
}

interface BookingProps {
  date: string;
  firstName: string;
  lastName: string;
  pronouns: string;
  email: string;
  handle: string;
  phone: string;
  type: TattooType;
  flashNumber: string;
  size: string;
  placement: string;
  description: string;
  location: Location;
  style: Style;
  availabilityDays: string[];
  availabilityTimes: string[];
  references: string[];
  concerns: string;
}

const Booking = () => {
  const cld = new Cloudinary({cloud: {
    cloudName: process.env.CLOUD_NAME,
    apiKey: process.env.CLOUDINARY_API_KEY,
    apiSecret: process.env.CLOUDINARY_API_SECRET}});
  const init: BookingProps = {
    date: new Date().toLocaleString(),
    firstName: '',
    lastName: '',
    pronouns: '',
    email: '',
    handle: '',
    phone: '',
    type: TattooType.Unspecified,
    flashNumber: '',
    size: '',
    placement: '',
    description: '',
    location: Location.Unspecified,
    style: Style.Unspecified,
    availabilityDays: [],
    availabilityTimes: [],
    references: [],
    concerns: '',
  };
  const [form, setForm] = React.useState(init);
  const [references, setReferences] = React.useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = React.useState<File[]>([]);
  const [fileLimit, setFileLimit] = React.useState(false);
  const [acknowledged, setAcknowledged] = React.useState(false);
  const [type, setType] = React.useState(TattooType.Unspecified);
  const [location, setLocation] = React.useState(Location.Unspecified);
  const [style, setStyle] = React.useState(Style.Unspecified);
  const [dayValues, setDays] =React.useState<string[]>([]);
  const [timeValues, setTimes] = React.useState<string[]>([]);
  const db = getFirestore();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.currentTarget.id]: event.currentTarget.value,
    });
  };

  const updateWeekdays = (event: React.ChangeEvent<HTMLInputElement>) => {
            // Destructuring
            const { value, checked } = event.target;
            const weekdays  = dayValues;

            // Case 1 : The user checks the box
            if (checked) {
              const newAvailability = [...weekdays, value];
                setDays(newAvailability);
                setForm({
                  ...form,
                  ['availabilityDays']: newAvailability,
                });
            }
    
            // Case 2  : The user unchecks the box
            else {
              const newAvailability = weekdays.filter(
                (event) => event !== value
              );
              setDays(newAvailability);
              setForm({
                ...form,
                ['availabilityDays']: newAvailability,
              });
            }
  }

  const updateTimes = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Destructuring
    const { value, checked } = event.target;
    const times  = timeValues;

    // Case 1 : The user checks the box
    if (checked) {
      const newAvailability = [...times, value];
        setTimes(newAvailability);
        setForm({
          ...form,
          ['availabilityTimes']: newAvailability,
        });
    }

    // Case 2  : The user unchecks the box
    else {
      const newAvailability = times.filter(
        (event) => event !== value
      );
      setTimes(newAvailability);
      setForm({
        ...form,
        ['availabilityTimes']: newAvailability,
      });
    }
}

const handleUploadFiles = (files: File[]) => {
  const uploaded = [...uploadedFiles];
  var limitExceeded = false;
  files.some((file: File) => {
    if (uploaded.findIndex((f) => f.name === file.name) === -1) {
      uploaded.push(file);
      if (uploaded.length == MAX_COUNT) setFileLimit(true);
      if (uploaded.length > MAX_COUNT) {
        alert(`You can only add a maximum of ${MAX_COUNT} files`);
        setFileLimit(false);
        limitExceeded = true;
        return true;
      }
    }
  })
  if (!limitExceeded) setUploadedFiles(uploaded);

  for (const file of uploaded ) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'client_references');
    fetch(url, {
      method: "POST",
      body: formData
    }).then(res => {
      return res.json();
    }).then(res => {
      addUrl(res);
    });
  }
}

async function addUrl(res: any) {
  if (res?.secure_url) {
    const newReferences = [...references, res.secure_url + ' '];
    setReferences(newReferences);
    setForm({...form, ['references']: newReferences});
  }
}

const handleFileEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
  const chosenFiles: File[] = Array.prototype.slice.call(event.target.files)
  handleUploadFiles(chosenFiles);
}

async function handleSubmit(e: React.SyntheticEvent) {
  e.preventDefault();
  try {
    const docRef = await addDoc(collection(db, 'booking-requests'), form);
    emailjs.init({
      publicKey: "bd5i_Wok_4cDRbfY4",
    });
    emailjs.send('service_eaaonqi', 'template_4lfdpe6', {...form})
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    console.log(`Document written with ID: ${docRef.id}`);
    alert('Booking Request Sent!');
    setForm(init);
  } catch(error) {
    console.error('Error submitting request.');
  }
  }

  return (
    <div className='booking'>
      <div className='title'>Booking Request</div>
      <div className='form'>
        <form id='bookingForm' onSubmit={handleSubmit}>
          <div className='subtitle'>About You</div>
          <div className='formField'>
            <span className='flexRow'>
              <input
                className='inputField firstName'
                id='firstName'
                type='text'
                name='firstName'
                value={form.firstName}
                onChange={handleChange}
                required
                placeholder='First Name*'
              />
              <input
                className='inputField lastName'
                id='lastName'
                type='text'
                name='lastName'
                value={form.lastName}
                onChange={handleChange}
                required
                placeholder='Last Name*'
              />
              <input
                className='inputField pronouns-web'
                id='pronouns'
                type='text'
                name='inputField'
                value={form.pronouns}
                onChange={handleChange}
                placeholder='Pronouns'
              />
            </span>
          </div>
          <div className='formField'>
            <span className='flexRow'>
              <input
                  className='inputField email'
                  id='email'
                  type='email'
                  name='email'
                  value={form.email}
                  onChange={handleChange}
                  required
                  placeholder='Email*'
                />
              <PhoneInput
                country={'us'}
                disableDropdown
                value={form.phone}
                onChange={(value) => {
                    setForm({...form, 'phone': value});
                }}
                />
              </span>
          </div>
          <div className='subtitle'>Tattoo</div>
          <div className='formField'>
            <select className='dropdown' id="type" name="type" required
              onChange={(event) => {
                const tattooType = event.target.value === "flash" ? TattooType.Flash : TattooType.Custom;
                setType(tattooType);
                setForm({...form, 'type': tattooType});
              }}>
              <option value="0">Tattoo type *</option>
              <option value={TattooType.Flash}>Flash</option>
              <option value={TattooType.Custom}>Custom</option>
            </select>
          </div>
          {
          type === TattooType.Flash &&
          <div className='formField'>
            <input
              className='inputField'
              id='flashNumber'
              type='text'
              name='flashNumber'
              value={form.flashNumber}
              onChange={handleChange}
              required
              placeholder='Flash Number*'
            />
          </div>
          }
          {
          type === TattooType.Custom &&
          <div className='custom'>
            <div className='formField'>
            <select className='dropdown' id="color" name="style" required
              onChange={(event) => {
                const style = event.target.value === "Black & White" ? Style.Black : Style.Color;
                setStyle(style);
                setForm({...form, 'style': style});
              }}>
              <option value="0">Black & White or Color? *</option>
              <option value={Style.Black}>Black & White</option>
              <option value={Style.Color}>Color</option>
            </select>
          </div>
            <div className='formField'>
              <textarea
                className='inputField'
                id='description'
                value={form.description}
                rows={3}
                name='description'
                placeholder='Describe any subjects, specific stylistic elements, or other aspects of your tattoo idea.*'
                onInput={(event) => {
                  setForm({...form, 'description': event.currentTarget.value});
              }}
                required
              />
            </div>
          </div>
          }
          <div className='formField'>
            <input
              className='inputField'
              id='size'
              type='text'
              name='size'
              value={form.size}
              onChange={handleChange}
              placeholder='What size?'
            />
          </div>
          <div className='formField'>
            <textarea
              className='inputField'
              id='placement'
              name='placement'
              value={form.placement}
              rows={1}
              placeholder='What placement? *'
              onInput={(event) => {
                setForm({...form, 'placement': event.currentTarget.value});
            }}
              required
            />
          </div>
          <div className='fileUploadContainer'>
            <input className='ignore' id='fileUpload' type='file' multiple accept='image' onChange={handleFileEvent} disabled={fileLimit}/>
            <label htmlFor='fileUploadButton'>
                Click to upload reference images (3 max)
            </label>
            <div className='fileUploadButtonContainer'>
              <label htmlFor='fileUpload'>
                <a id='fileUploadButton' className='uploadFileButton'> Upload File </a>
              </label>
            </div>
          </div>
          <div className="uploadedFilesList">
            {uploadedFiles.map(file => (
              <div>
                {file.name}
              </div>
            ))}
          </div>
          <div className='subtitle'>Scheduling</div>
          {/* <div className='formField'>
            <select className='dropdown' id="location" name="location" required
              onChange={(event) => {
                const location = event.target.value === "Milpitas" ? Location.Milpitas : Location.Duluth;
                setLocation(location);
                setForm({...form, 'location': location});
              }}>
              <option value="0">Studio Location *</option>
              <option value={Location.Milpitas}>Milpitas/San Jose</option>
              <option value={Location.Duluth}>Duluth (Guest Spot)</option>
            </select>
          </div> */}
        <div className="checkbox-selector">
        <label htmlFor="checkbox-selector" className="checkbox-selector-label">Availability</label><br/>
          <input type="checkbox" id="weekday-mon" name="weekdays" value="Monday" onChange={updateWeekdays}/>
          <label htmlFor="weekday-mon" className="weekday">M</label>
          <input type="checkbox" id="weekday-tue" name="weekdays" value="Tuesday" onChange={updateWeekdays}/>
          <label htmlFor="weekday-tue" className="weekday">T</label>
          <input type="checkbox" id="weekday-wed" name="weekdays" value="Wednesday" onChange={updateWeekdays}/>
          <label htmlFor="weekday-wed" className="weekday">W</label>
          <input type="checkbox" id="weekday-thu" name="weekdays" value="Thursday" onChange={updateWeekdays}/>
          <label htmlFor="weekday-thu" className="weekday">T</label>
          <input type="checkbox" id="weekday-fri" name="weekdays" value="Friday" onChange={updateWeekdays}/>
          <label htmlFor="weekday-fri" className="weekday">F</label>
          <input type="checkbox" id="weekday-sat" name="weekdays" value="Saturday" onChange={updateWeekdays}/>
          <label htmlFor="weekday-sat" className="weekday">Sa</label>
          {/* <input type="checkbox" id="weekday-sun" name="weekdays" value="Sunday" onChange={updateWeekdays}/>
          <label htmlFor="weekday-sun" className="weekday">Su</label> */}
        </div>
        <div className="checkbox-selector">
        <label htmlFor="checkbox-selector" className="checkbox-selector-label">Times</label><br/>
          <input type="checkbox" id="time-morning" name="times" value="Morning (10am to 12pm)" onChange={updateTimes}/>
          <label htmlFor="time-morning" className="time">10am - 12pm</label>
          <input type="checkbox" id="time-afternoon" name="times" value="Afternoon (12pm to 3pm)" onChange={updateTimes}/>
          <label htmlFor="time-afternoon" className="time">12pm - 3pm</label>
          <input type="checkbox" id="time-evening" name="times" value="Evening (3pm to 6pm)" onChange={updateTimes}/>
          <label htmlFor="time-evening" className="time">3pm - 6pm</label>
        </div>
        <div className="disclaimer">I will try my best to accommodate your schedule, but weekend and evening availability is limited.</div>
        <div className='formField'>
          <textarea
            className='inputField'
            id='concerns'
            value={form.concerns}
            rows={1}
            name='concerns'
            placeholder='Anything else I should know?'
            onInput={(event) => {
              setForm({...form, 'concerns': event.currentTarget.value});
          }}></textarea>
        </div>
        <div className='formField'>
            <input
              className='inputField'
              id='handle'
              type='text'
              name='inputField'
              value={form.handle}
              onChange={handleChange}
              placeholder="Instagram handle (if you'd like to be tagged)"
            />
          </div>
        <div className="acknowledgement">
          <input type="checkbox" onChange={(e)=>{ setAcknowledged(e.target.checked)}}/>
          <label htmlFor="acknowledgement" className="acknowledgementText"> I confirm that I have read the entirety of the available <a href="https://thekathylee.com/info">booking information.</a>
          </label>
        </div>
        <button id='submitButton'className='submit' type='submit' disabled={!acknowledged}>Submit</button>
        </form>
      </div>
    </div> 
  );
}

export default Booking;
