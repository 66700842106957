import React, { FC } from 'react';
import './aftercare.scss';

interface AftercareProps {}
const Aftercare: FC<AftercareProps> = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
  <div className='aftercare'>
    <div className="title">Tattoo Care</div>
    <div className="subtitle">Prep</div>
    Before your appointment, please make sure to:
    <div className='content'>
      <ul>
        <li>moisturize the area to be tattooed (the week before)<br/></li>
        <li>stay hydrated!<br/></li>
        <li>eat a meal before your appointment and feel free to bring snacks<br/></li>
        <li>bring entertainment or anything else you may need for your personal comfort.<br/></li>
      </ul>
    </div>
    <br/>Please don't drink the night before and or show up intoxicated. Also avoid drinking for 48 hours after getting the tattoo if you can... it will heal better :-)
    <div className="spacer-lg"></div>

    <div className="subtitle">Aftercare (2 weeks)</div>
    <div className='content'>
      <ul>
        <li>avoid swimming, hot tubs, and submerging for at least the first two weeks!!<br/></li>
        <li>avoid wearing clothing that causes too much friction on the area<br/></li>
        <li>avoid direct sunlight<br/></li>
        <li>once you remove the second skin, wash the tattoo with soap and lightly moisturize with aftercare ointment (or Aquaphor), 2-3x a day<br/></li>
      </ul>
    </div>
    <div className="spacer-lg"></div>

    <div className="subtitle">Touch Ups</div>
    Your first touch up is free and included in the cost of your tattoo, if necessary. 
    <br/>After a minimum of <span className="bold">two weeks</span>, if you need a touch up, send me an email with a photo of your healed tattoo.
    <br/>If you don't need a touch up, send me a healed photo anyways ;-) Thank you!
    <div className="spacer"></div>
    <div className="spacer"></div>
    You can contact me with any questions or concerns at: <a href="mailto:booking.kathylee@gmail.com">booking.kathylee@gmail.com</a>
    <div className="spacer-lg"></div>

  </div>
  );
}

export default Aftercare;
